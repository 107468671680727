// import adeconnectConfig from '@/adeconnectConfig'

export default [
  {
    title: 'Home',
    route: 'home',
    icon: 'HomeIcon',
  },
  {
    title: 'Cliente',
    route: 'cliente',
    icon: 'KeyIcon',
  },
  {
    title: 'Empresa',
    route: 'empresa',
    icon: 'FileIcon',
  },
  {
    title: 'Processamento',
    route: 'processamento',
    icon: 'PlayIcon',
  },
  {
    title: 'Parâmetros Fiscais',
    route: 'paramfisc',
    icon: 'ToolIcon',
  },
  {
    title: 'Upload de arquivos',
    route: 'fileupload',
    icon: 'ArchiveIcon',
  },
  // Ia direto para o site keycloak
  // {
  //   title: 'Usuários',
  //   href: adeconnectConfig.keycloakURL.replace('/realms/adeconnect', ''),
  //   icon: 'UserIcon',
  // },
  {
    title: 'Usuários',
    route: 'users',
    icon: 'UserIcon',
  },

]
